<template>
  <div>
    <div class="min-vh-100">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col xl="4" class="text-center text-sm-left mb-3 mb-sm-0">
          <h1 class="mr-sm-4 header-main text-uppercase">
            รายการคะแนนในระบบ
          </h1>
        </b-col>
        <b-col xl="8" class="text-right">
          <div class="d-flex justify-content-end">
            <b-input-group class="panel-input-serach">
              <b-form-input
                class="input-serach"
                placeholder="ค้นหา"
                v-model="filter.search"
                @keyup="handleSearch"
              ></b-form-input>
              <b-input-group-prepend @click="btnSearch">
                <span class="icon-input m-auto pr-2">
                  <font-awesome-icon icon="search" title="View" />
                </span>
              </b-input-group-prepend>
            </b-input-group>

            <b-button v-b-toggle.sidebar-1 class="btn-filter">
              <font-awesome-icon
                icon="filter"
                title="filter-btn"
                class="text-white mr-0 mr-sm-1"
              />
              <span class="d-none d-sm-inline"
                >ค้นหา ({{ countType+countStatus }})</span
              >
            </b-button>
            <b-button
              class="btn-main h-100"
              @click="exportData"
              v-if="selectedTab < 3"
              >ส่งออกข้อมูลคะแนนในระบบ</b-button
            >
              <!-- :disabled="exportFilter.partnerId.length == 0" -->
          </div>
        </b-col>
      </CRow>
      
      <b-sidebar
        id="sidebar-1"
        title="ค้นหาแบบละเอียด"
        backdrop
        shadow
        backdrop-variant="dark"
        right
        ref="filterSidebar"
      >
        <div class="px-3 py-2">
          <div class="text-right">
            <button
              type="button"
              class="btn btn-link px-0"
              @click="onClearFilter()"
            >
              ล้างค่า
            </button>
          </div>

          <InputSelect
            class="mb-4"
            title="ประเภท"
            name="type"
            :options="optionsType"
            v-model="filter.type"
            @onDataChange="handleChangeType"
            valueField="value"
            textField="name"
          />
          <InputSelect
            class="mb-4"
            title="สถานะ"
            name="status"
            :options="optionsStatus"
            v-model="filter.status"
            @onDataChange="handleChangestatus"
            valueField="value"
            textField="name"
          />

          <div class="text-center mt-4">
            <button
              type="button"
              class="btn btn-purple button"
              @click="getDataFilter"
            >
              ค้นหา
            </button>
          </div>
        </div>
      </b-sidebar>

      <b-row class="no-gutters px-3 px-sm-0 mt-2 overflow-auto">
        <b-col class="">
          <b-button-group class="btn-group-status">
            <b-button
              v-for="(item, index) in statusList"
              :key="index"
              @click="onChangeTab(item.id)"
              :class="{ menuactive: activeItem == item.id }"
              >{{ item.name }}</b-button
            >
          </b-button-group>
        </b-col>
      </b-row>

      <b-row class="mt-2 mx-2 mx-sm-0" no-gutters>
        <b-col sm="4" class="mb-2 mb-sm-0"
          ><div class="p-2 finance-box mr-0 mr-sm-1 bg-white">
            <div class="d-flex justify-content-between align-items-center border rounded p-2">
                <div>
                    <img src="@/assets/images/icons/getfin-icon-mini.png" width="34" class="mr-2 bg-white rounded-circle" />
                    <span>คะแนนสะสมของ Brand</span>
                </div>
                <div class="text-orange font-weight-bold">
                    {{ itemsGetfinAccount.point | numeral("0,0") }}
                </div>
            </div>
          </div>
        </b-col>
        <b-col sm="4"
          ><div class="p-2 bg-white finance-box mx-0 mx-sm-1">
            <div class="d-flex justify-content-between align-items-center p-2 border bdc-white">
                <span>คะแนนสะสม Seller</span>
                <div class="text-orange font-weight-bold">
                    {{ overview.seller | numeral("0,0") }}
                </div>
            </div>
          </div>
        </b-col>
        <b-col sm="4"
          ><div class="p-2 bg-white finance-box ml-0 ml-sm-1">
            <div class="d-flex justify-content-between align-items-center p-2 border bdc-white">
                <span>คะแนนสะสม Member</span>
                <div class="text-orange font-weight-bold">
                    {{ overview.member | numeral("0,0") }}
                </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <CRow class="mt-3" v-if="activeItem == 3"> 
        <b-col md="1" offset-md="11">
          <b-dropdown id="dropdown-left" right :text="filter.year || 'ปี'" variant="outline-dark" class="w-100" >
            <b-dropdown-item-button @click="changeYear(0)"> ปี </b-dropdown-item-button>
            <b-dropdown-item-button
              v-for="(item, index) in years"
              :key="index"
              @click="changeYear(item)">
              {{item}}
              </b-dropdown-item-button>
          </b-dropdown>
        </b-col>
      </CRow>

      <div class="mt-3 bg-white">
        <b-row class="no-gutters px-3 pt-3 pt-sm-0 px-sm-0">
          <b-col>
            <b-table
              class="table-list"
              striped
              responsive
              hover
              :items="items"
              :fields="selectedTab < 3 ? fields : expireFields"
              :busy="isBusy"
              show-empty
              empty-text="ไม่พบข้อมูล"
            >
              <!-- <template v-slot:cell(ids)="data">
                <b-form-checkbox
                  size="lg"
                  class="ml-3"
                  :value="selectedTab < 3 ? data.item.userGUID : data.item.startDate "
                  v-model="selected"
                ></b-form-checkbox>
              </template>
              <template v-slot:head(ids)="data">
                <b-form-checkbox
                  size="lg"
                  class="ml-3"
                  :ref="data.field.key"
                  v-model="selectedAll"
                  @change="onChangeSelectedAll"
                ></b-form-checkbox>
              </template> -->
              <template v-slot:cell(updatedTime)="data">
                <span>{{
                  new Date(data.item.updatedTime) | moment($formatDateTime)
                }}</span>
              </template>
              <template v-slot:cell(name)="data">
                <div>
                  <p class="m-0">{{ data.item.firstname }} {{ data.item.lastname }}</p>
                </div>
              </template>
              <template v-slot:cell(withdrawNumber)="data">
                <div v-if="data.item.withdrawNumber == ''">-</div>
                <div v-else>{{ data.item.withdrawNumber }}</div>
              </template>
              <template v-slot:cell(point)="data">
                <p class="m-0">{{ data.item.point | numeral("0,0") }}</p>
              </template>
              <template v-slot:cell(date)="data">
                <div class="d-flex pl-3">
                  <div class="w-120">
                    เดือน <span class="mr-4">{{ new Date(data.item.startDate) | moment("MMMM")}}</span>
                  </div>
                  <div>
                    (<span>{{ new Date(data.item.startDate) | moment("D")}}</span> - 
                    <span>{{ new Date(data.item.endDate) | moment($formatDate) }}</span>)
                  </div>
                </div>
              </template>
              <template v-slot:cell(year)="data">
                <span>{{ new Date(data.item.startDate) | moment("YYYY")}}</span>
              </template>
              <template v-slot:cell(action)="data">
                <div class="d-flex justify-content-center">
                  <router-link v-if="activeItem < 3" :to="`/${data.item.typeId == 4 ? 'affiliate' : 'member'}/details/${data.item.userGUID}`">
                    <b-button
                      variant="link"
                      class="px-1 py-0 btn-link text-dark"
                    >
                      ดูรายละเอียด
                    </b-button>
                  </router-link>
                  <router-link v-else-if="activeItem == 3" :to="'/point/expire/details/'+data.item.startDate">
                    <b-button
                      variant="link"
                      class="px-1 py-0 btn-link text-dark"
                    >
                      ดูรายละเอียด
                    </b-button>
                  </router-link>
                </div>
              </template>
              <template v-slot:cell(status)="data">
                <div :class="[data.item.statusId == 0 ? 'text-danger':'text-success']">
                  {{data.item.status}}
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="no-gutters px-3 px-sm-0">
          <b-col
            class="form-inline justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex m-3">
              <b-pagination
                v-model="filter.pageNo"
                :total-rows="rows"
                :per-page="filter.perpage"
                class="m-md-0"
                @change="pagination"
                align="center"
              ></b-pagination>

              <div class="ml-2">
                <p class="total-record-paging text-nowrap text-center">
                  {{ totalRowMessage }}
                </p>
              </div>
            </div>

            <b-form-select
              class="mr-sm-3 select-page"
              v-model="filter.perpage"
              @change="hanndleChangePerpage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>
    </div>

    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalAlert ref="modalAlert" :text="modalMessage" />
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import InputSelect from "@/components/inputs/InputSelect";

export default {
  name: "WithdrawIndex",
  components: {
    ModalAlertError,
    ModalLoading,
    ModalAlert,
    ModalAlertConfirm,
    InputSelect
  },
  data() {
    return {
      selected: null,
      options: [],
      statusList: [
        {count: 0, id: 1, name: "คะแนนสะสมในระบบ"},
        {count: 0, id: 2, name: "คะแนนที่ถูกใช้ไป"},
        {count: 0, id: 3, name: "คะแนนที่หมดอายุ"}
      ],
      modalMessage: "",
      activeItem: 1,
      requestDeleteUser: {
        userId: null,
      },
      fields: [
        // {
        //   key: "ids",
        //   label: ""
        // },
        {
          key: "updatedTime",
          label: "อัพเดทล่าสุด",
          class: "w-100px",
        },
        {
          key: "name",
          label: "ชื่อ - นามสกุล เจ้าของคะแนน",
          class: "w-225px",
        },
        {
          key: "point",
          label: "จำนวนคะแนนคงเหลือ",
          class: "w-100px",
        },
        {
          key: "typeUser",
          label: "ประเภท",
          class: "w-100px",
        },
        {
          key: "status",
          label: "สถานะบัญชี",
          class: "w-100px",
        },
        {
          key: "action",
          label: "",
          class: "w-100px",
        },
      ],
      expireFields: [
        // {
        //   key: "ids",
        //   label: ""
        // },
        {
          key: "date",
          label: "เดือนและช่วงเวลา",
          tdClass: "text-left",
        },
        {
          key: "year",
          label: "ปี",
          class: "w-100px",
        },
        {
          key: "point",
          label: "จำนวนที่หมดอายุ",
          class: "w-100px",
        },
        {
          key: "action",
          label: "",
          class: "w-100px",
        },
      ],
      items: [],
      isBusy: false,
      value: false,
      rows: 0,
      filter: {
        search: "",
        type: -1,
        status: -1,
        pageNo: 1,
        perpage: 10,
        year: 0
      },
      filterSubmit: {
        search: "",
        type: -1,
        status: -1,
        pageNo: 1,
        perpage: 10,
        year: 0
      },
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
      totalRowMessage: "",
      items: [],
      isBusy: false,
      isDisable: false,
      checkAll: false,
      selectAllCb: false,
      errorDate: false,
      selected: [],
      selectedAll: false,
      allItems: [],
      filterStatus: null,
      amount: 0,
      optionsType: [
        {value: -1, name: "ประเภท" },
        {value: 4, name: "Customer"},
        {value: 2, name: "Member"}
      ],
      optionsStatus: [
        {value: -1, name: "สถานะ" },
        {value: 1, name: "ใช้งาน"},
        {value: 0, name: "ระงับ"}
      ],
      overview: {
        all: 0,
        seller: 0,
        member: 0
      },
      selectedTab: 1,
      years:[],
      timer: null,
      itemsGetfinAccount: {
        userGUID: "",
        firstname: "",
        lastname: null,
        point: 0,
        typeId: 0,
        typeUser: null,
        statusId: 0,
        status: null,
        updatedTime: ""
      }
    };
  },
  computed: {
    countType: function () {
      var count = 0;
      if (this.filterSubmit.type.length > 0) count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countStatus: function () {
      var count = 0;
      if (this.filterSubmit.status.length > 0) count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
  },
  created: async function () {
    await this.getList(this.selectedTab);
    await this.getAllData(this.selectedTab);
    this.$isLoading = true;
    this.activeItem = 1;
  },
  watch: {
    selected: function () {
      if (this.selected.length == this.allItems.count) {
        this.selectedAll = true;
      } else {
        this.selectedAll = false;
      }
    },
    filterSubmit: function () {
      if(this.selectedAll){
        this.handleSelectedAll(this.selectedAll);
      }
    },
  },
  methods: {
    changeYear(year){
      this.filter.year = year;
      this.filter.pageNo = 1;
      this.getList();
    },
    onChangeSelectedAll: async function (e){
      await this.handleSelectedAll(e);
    },
    handleSelectedAll: async function (e){
      this.selectedAll = e;
      if(e){
        await this.getAllData(this.selectedTab);

        this.selected = [];
        if(this.selectedTab < 3){
          this.allItems.dataList.forEach((element, index) => {
            this.selected.push(element.userGUID);
          });
        }else{
          this.allItems.dataList.forEach((element, index) => {
            this.selected.push(element.startDate);
          });
        }
      } else {
        this.selected = [];
      } 
    },
    getAllData: async function (tab = false) {
      return;

      var path = '';
      if(this.selectedTab == 1) path = 'point';
      else if(this.selectedTab == 2) path = 'point/used';
      else if(this.selectedTab == 3) path = 'point/expire';

      let filterAll = {
        search: "",
        status: [],
        pageNo: 1,
        perpage: -1,
        type:[]
      };
      if(this.filter.type > -1) filterAll.type.push(this.filter.type)
      if(this.filter.status > -1) filterAll.status.push(this.filter.status)
      
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/${path}/List`,
        null,
        this.$headers,
        filterAll
      );
      if (resData.result == 1) {
        this.allItems = resData.detail;
      }
    },
    getList: async function (tab = false) {
      var path = '';
      if(tab && this.selectedTab != tab) this.selectedTab = tab

      if(this.selectedTab == 1) path = 'point';
      else if(this.selectedTab == 2) path = 'point/used';
      else if(this.selectedTab == 3) path = 'point/expire';


      this.isBusy = true;
      var filter = {...this.filter};
      filter.type = [];
      filter.status = [];
      if(!tab) {
        if(this.filter.type > -1) filter.type.push(this.filter.type)
        if(this.filter.status > -1) filter.status.push(this.filter.status)
      } else {
        filter.search = "";
        filter.pageNo = 1;
      }

      this.filterSubmit = {...filter};

      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/${path}/List`,
        null,
        this.$headers,
        this.filterSubmit
      );
      if (resData.result == 1) {
        this.items = resData.detail.dataList;
        this.rows = resData.detail.count;
        this.overview = resData.detail.overview;
        this.isBusy = false;
        if(this.selectedTab == 3) this.years = resData.detail.year
        if(this.selectedTab == 1) this.itemsGetfinAccount = resData.detail.getfinAccount;
      }
    },
    getDataFilter() {
      this.filter.pageNo = 1;
      this.$refs.filterSidebar.hide(true);
      this.getList();
    },
    onClearFilter() {
      this.filter.pageNo = 1;
      this.filter.type = -1;
      this.filter.status = -1;
      this.$refs.filterSidebar.hide(true);
      this.getList();
    },
    pagination(Page) {
      this.filter.pageNo = Page;
      this.getList();
    },
    hanndleChangePerpage(value) {
      this.filter.pageNo = 1;
      this.filter.perpage = value;
      this.getList();
    },
    handleSearch(e) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.filter.pageNo = 1;
        this.getList();
      }, 800);
    },
    btnSearch() {
      this.filter.pageNo = 1;
      this.getList();
    },
    onChangeTab: async function (id) {
      this.activeItem = id;
      this.filter.pageNo = 1;
      this.filter.type = -1
      this.filter.status = -1
      this.filter.search = ""
      await this.getList(id);
      await this.getAllData(id);
    },
    updateStatusById: async function (id) {
      this.selected.push(id);
    },
    handleApprove(){
      if(this.selected.length) this.$refs.modalApprove.show();
    },
    exportData: async function() {
      this.$refs.modalLoading.show();

      var path = "";
      if(this.selectedTab == 1) path = 'point';
      else if(this.selectedTab == 2) path = 'point/used';

      axios({
        url: `${this.$baseUrl}/api/${path}/List/export`,
        method: "post",
        headers: this.$headers,
        responseType: "blob",
        data: this.filterSubmit
      })
        .then(response => {
          this.$refs.modalLoading.hide();
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          var dateExcel = moment().format("DDMMYYYYhhmmss");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            `Point-List-` + dateExcel + `.xlsx`
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(error => {
          console.log(error);
        });
    },
    handleChangeType: async function (value) {
      this.filter.type = value;
    },
    handleChangestatus: async function (value) {
      this.filter.status = value;
    },
  },
};
</script>       

<style scoped>
.menuactive {
  color: #ffb300 !important;
}

.review-shortdesc {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
}

.w-300 {
  width: 300px;
}

.finance-box {
  border-radius: 10px;
  font-size: 1.15rem;
}

.finance-box .text-orange {
  font-size: 1.5rem;
}

.finance-box .border {
  border-radius: 10px !important;
  border-color: #f3591f !important;
}
.finance-box .border.bdc-white {
  border-color: white !important;
}
.text-orange {
  color: #f3591f;
}

.bgc-orange {
  background-color: #f3591f;
  color: white;
}

.w-120 {
  width: 120px;
}
::v-deep .dropdown-menu-right{
  width: 60px !important;
}
</style>